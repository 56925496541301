import React from 'react';
import Logo from "./common/Logo";
import PrivacyLinks from "./privacy/PrivacyLinks";
import Divider from "./common/Divider";
import AppFeaturesList from "./AppFeaturesList";
import LearnToPlayLink from "./RulesLink";
import AppLinks from "./AppLinks";
import PromoYoutube from "./promo/PromoYouTube";
import {useParams} from "react-router-dom"
import {Helmet} from "react-helmet";

function PrivateTableInvitation() {

    const { gameId } = useParams()
    const url = 'https://play29.com/play/' + gameId
    const deepLink = 'play29://play/' + gameId

    return <div>
        <Helmet>
            <title>Private Table Invite</title>
            <meta name="description" content="Lorem ipsum dolor sit amet" />
            <meta property="al:ios:url" content={deepLink} />
            <meta property="al:android:url" content={deepLink} />
            <meta property="og:title" content="Private Table Invite" />
            <meta property="og:description" content="Let's Play!!!" />
            <meta property="og:url" content={url} />
        </Helmet>
        <Logo />
        {/*<AppLinks/>*/}
        <h1>Private Table Invite {gameId}</h1>
        <h3>🤖 No Robots. Ever.</h3>
        <h3>Only Real Players.</h3>
        <h3 style={{color: "#bbb"}}>29 Card Game</h3>
        <AppFeaturesList/>
        <Divider />
        <PromoYoutube />
        <LearnToPlayLink/>
        <PrivacyLinks/>
        <Divider />
        <AppLinks/>
    </div>
}

export default PrivateTableInvitation;
