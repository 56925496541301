import React from "react";
import Logo from "../common/Logo";

function DataRemovalRequest() {

    return <div>
        <Logo/>
        <p><strong>Data Removal Request</strong></p>
        <p>
            Please send an email to <strong>Play29.com@gmail.com</strong> with your email address and the kind of
            data
            you would like to be removed from the app.
            You can also request your data to be deleted directly on the app from <strong>Profile tab -> Delete Account</strong>
        </p>
        <hr/>
        <h3>
            Play29.com@gmail.com
        </h3>
    </div>

}

export default DataRemovalRequest;
