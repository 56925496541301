import './App.css';
import {BrowserRouter as Router, Route, Switch, Redirect} from "react-router-dom";
import React from "react";
import Homepage from "./components/Homepage";
import DataRemovalRequest from "./components/privacy/DataRemovalRequest";
import PrivacyPolicy from "./components/privacy/PrivacyPolicy";
import TermsOfService from "./components/privacy/TermsOfService";
import PrivateTableInvitation from "./components/PrivateTableInvitation";
import TableInvitation from "./components/TableInvitation";

function App() {

    return (
        <div className="App">
            <header className="App-header">
                <Router>
                    <Switch>
                        <Route exact path="/">
                            <Homepage />
                        </Route>
                        <Route exact path="/play/:gameId">
                            <PrivateTableInvitation />
                        </Route>
                        <Route exact path="/table/:tableId">
                            <TableInvitation />
                        </Route>
                        <Route exact path="/privacy">
                            <DataRemovalRequest />
                        </Route>
                        <Route exact path="/privacy-policy">
                            <PrivacyPolicy />
                        </Route>
                        <Route exact path="/terms-of-service">
                            <TermsOfService />
                        </Route>
                        <Redirect to="/" />
                    </Switch>
                </Router>
            </header>
        </div>
    );
}

export default App;
