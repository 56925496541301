import React from "react";

function AppFeaturesList() {

    const features = [
        '➕ Private Tables',
        '▶ Public Tables',
        '📺 Stream live Games',
        '🔀 Self Shuffle',
        '⚡ Buzz ⚡',
        '💬 Chat',
        '🏅 Leader board',
        '👑 Tournaments'
    ];

    const list = features.map(feature => <div>{feature}</div>);

    return <div style={{color: '#ff7bff'}}>
        {list}
    </div>
}

export default React.memo(AppFeaturesList)