import React from 'react'
import Divider from "../common/Divider";
import {Link} from "react-router-dom";

function PrivacyLinks() {

    const styleLink = { textDecoration: 'none' };

    return <div className="row justify-content-around">
        <a href={'/pdf/Rules-Regulations-of-Play-29.pdf'} style={styleLink}>🎮  Rules & Regulations</a>
        <Divider />
        <Link to={'/privacy-policy'} style={styleLink}>🔒  Privacy Policy </Link>
        <Divider />
        <Link to={'/privacy'} style={{ textDecoration: 'none' }}>🕹 Data Removal Consent </Link>
        <Divider />
        <Link to={'/terms-of-service'} style={{ textDecoration: 'none' }}>🧸 Terms and conditions</Link>
    </div>
}

export default React.memo(PrivacyLinks)