import React from "react";
import iosLogo from '../assets/logo/ios_app_store_black.svg'
import androidLogo from '../assets/logo/google-play-badge.png'

function AppLinks() {

    // const iosUrl = 'https://apps.apple.com/app/play-29/id6502718896';
    const iosUrl = 'https://apps.apple.com/us/app/play-29/id1550659960';
    const androidUrl = "https://play.google.com/store/apps/details?id=com.play29.twentyninecardgame";
    // const androidUrl = "https://play.google.com/store/apps/details?id=com.play29.www";
    // const androidUrl = "https://play.google.com/store/apps/details?id=com.play29.cardgame29";
    // const androidUrl = "https://play.google.com/store/apps/details?id=com.play29.twentyninecardgame";
    // const androidUrl = "https://play.google.com/store/apps/details?id=online.twentyninecardgame";
    // const androidUrl = 'https://play.google.com/store/apps/details?id=com.play29.twentynine.cardgameonlineplay';
    // const androidUrl = 'https://play.google.com/store/apps/details?id=com.play29.www';
    const iosStyle = {height: 50};
    const androidStyle = {height: 70};

    return <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <a href={iosUrl}>
            <img src={iosLogo} alt="Download Play 29 iOS" style={iosStyle}/>
        </a>
        <a href={androidUrl}>
            <img src={androidLogo} alt='Get it on Google Play' style={androidStyle}/>
        </a>
    </div>

}

export default React.memo(AppLinks)
