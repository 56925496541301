import React from "react";

function RulesLink() {
    const pagatUrl = 'https://www.pagat.com/jass/29.html';
    const styleLink = { textDecoration: 'none' };
    return <h3>
        <a href={pagatUrl} target="_blank" rel="noreferrer" style={styleLink}>📃 Rules</a>
    </h3>
}

export default React.memo(RulesLink)