import React from 'react';

// const YouTube = lazy(() => import('react-youtube'));

// const renderLoader = () => <p>Loading...</p>;

function PromoYoutube() {

    return <div>
        {/*<Suspense fallback={renderLoader()} >*/}
        {/*    <YouTube videoId="0H-uJyeGDuo" />*/}
        {/*</Suspense>*/}
        <iframe width="560" height="315" src="https://www.youtube.com/embed/0H-uJyeGDuo" title="YouTube video player"
                frameBorder="0"
                loading="lazy"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen/>
    </div>
}

export default React.memo(PromoYoutube);