import React from 'react';
import Logo from "../common/Logo";

export default function PrivacyPolicy() {
    return <div>
        <Logo/>
        <h1>Privacy Policy</h1>
        <p>
            Play29.com LLC built the Play 29 app (Web + Android + iOS) as
            an Ad Supported app. This SERVICE is provided by
            Play29.com LLC at no cost and is intended for use as
            is.
        </p>
        <p>
            This part of the Play 29 web page is used to inform Play 29 users regarding our policies on the manner
            in which Play 29 accesses, uses, stores, or shares user data ("Personal Information").
        </p>
        <p>
            If you choose to use Play 29, then you agree to
            the collection and use of information in relation to this
            policy. The Personal Information that we collect is
            used for providing and improving Play 29. We will not use or share your information with
            anyone except as described in this Privacy Policy.
        </p> <p>
        The terms used in this Privacy Policy have the same meanings
        as in our Terms and Conditions, which is accessible at
        Play 29 unless otherwise defined in this Privacy Policy.
    </p> <p><strong>Information Collection and Use</strong></p> <p>
        For a better experience, while using Play 29, we
        may require you to provide us with certain personally
        identifiable information, including but not limited to name, email, photo. The information that
        we request will be retained by us and used as described in this privacy policy.
    </p>
        <div><p>
            The app does use third party services that may collect
            information used to identify you.
        </p> <p>
            Link to privacy policy of third party service providers used
            by the app
        </p>
            <ul>
                <li><a href="https://www.google.com/policies/privacy/" target="_blank" rel="noopener noreferrer">Google
                    Play Services</a></li>
                <li><a href="https://support.google.com/admob/answer/6128543?hl=en" target="_blank"
                       rel="noopener noreferrer">AdMob</a></li>
                <li><a href="https://firebase.google.com/policies/analytics" target="_blank" rel="noopener noreferrer">Google
                    Analytics for Firebase</a></li>
                <li><a href="https://firebase.google.com/support/privacy/" target="_blank" rel="noopener noreferrer">Firebase
                    Crashlytics</a></li>
                <li><a href="https://www.facebook.com/about/privacy/update/printable" target="_blank"
                       rel="noopener noreferrer">Facebook</a></li>
            </ul>
        </div>
        <p><strong>Log Data</strong></p> <p>
        We want to inform you that whenever you
        use our Play 29, in a case of an error in the app
        we collect data and information (through third party
        products) on your phone called Log Data. This Log Data may
        include information such as your device Internet Protocol
        (“IP”) address, device name, operating system version, the
        configuration of the app when utilizing Play 29,
        the time and date of your use of Play 29, and other
        statistics.
    </p>

        <p><strong>Personal Data</strong></p> <p>
        We want to inform you that whenever you win any public games
        such as Quick, Classic, and etc. we will upload your personal information
        including but not limited to your name, photo, points collected, and user ID
        to our server to be considered for the global leaderboard. Your personal data such as
        name, photo, game statistics, and etc. may be publicly viewable throughout the app.
        Please send an email to <strong>Play29.com@gmail.com</strong> to request data removal. Please
        include your user ID by going to your profile and tap "Share Profile". Also, specify
        exactly what you would like to be removed or write "Delete All" as the subject. It may
        take up to 90 days for your data to be fully removed from our system.
    </p>

        <p><strong>Cookies</strong></p> <p>
        Cookies are files with a small amount of data that are
        commonly used as anonymous unique identifiers. These are sent
        to your browser from the websites that you visit and are
        stored on your device's internal memory.
    </p> <p>
        Play 29 does not use these “cookies” explicitly. However,
        the app may use third party code and libraries that use
        “cookies” to collect information and improve their services.
        You have the option to either accept or refuse these cookies
        and know when a cookie is being sent to your device. If you
        choose to refuse our cookies, you may not be able to use some
        portions of Play 29.
    </p> <p><strong>Service Providers</strong></p> <p>
        We may employ third-party companies and
        individuals due to the following reasons:
    </p>
        <ul>
            <li>To facilitate our Service;</li>
            <li>To provide the Service on our behalf;</li>
            <li>To perform Service-related services; or</li>
            <li>To assist us in analyzing how our Service is used.</li>
        </ul>
        <p>
            We want to inform users of Play 29
            that these third parties have access to your Personal
            Information. The reason is to perform the tasks assigned to
            them on our behalf. However, they are obligated not to
            disclose or use the information for any other purpose.
        </p> <p><strong>Security</strong></p> <p>
        We value your trust in providing us your
        Personal Information, thus we are striving to use commercially
        acceptable means of protecting it. But remember that no method
        of transmission over the internet, or method of electronic
        storage is 100% secure and reliable, and we cannot
        guarantee its absolute security.
    </p> <p><strong>Links to Other Sites</strong></p> <p>
        Play 29 may contain links to other sites. If you click on
        a third-party link, you will be directed to that site. Note
        that these external sites are not operated by us.
        Therefore, we strongly advise you to review the
        Privacy Policy of these websites. We have
        no control over and assume no responsibility for the content,
        privacy policies, or practices of any third-party sites or
        services.
    </p> <p><strong>Children’s Privacy</strong></p> <p>
        Play 29 do not address anyone under the age of 13.
        We do not knowingly collect personally
        identifiable information from children under 13 years of age. In the case
        we discover that a child under 13 has provided
        us with personal information, we immediately
        delete this from our servers. If you are a parent or guardian
        and you are aware that your child has provided us with
        personal information, please contact us so that
        we will be able to do necessary actions.
    </p> <p><strong>Data Removal Request</strong></p> <p>
        Please send an email to <strong>Play29.com@gmail.com</strong> with your email address and the kind of data you
        would like to be removed from the app.
        You can also request it directly on the app from <strong>Profile tab -> Delete Account</strong>
    </p> <p><strong>Changes to This Privacy Policy</strong></p> <p>
        We may update our Privacy Policy from
        time to time. Thus, you are advised to review this page
        periodically for any changes. We will
        notify you of any changes by posting the new Privacy Policy on
        this page.
    </p> <p>This policy is effective as of 2021-02-18</p> <p><strong>Contact Us</strong></p> <p>
        If you have any questions or suggestions about our
        Privacy Policy, do not hesitate to contact us at play29.com@gmail.com.
    </p> <p>This privacy policy page was created at <a href="https://privacypolicytemplate.net" target="_blank"
                                                       rel="noopener noreferrer">privacypolicytemplate.net </a>and
        modified/generated by <a href="https://app-privacy-policy-generator.nisrulz.com/" target="_blank"
                                 rel="noopener noreferrer">App Privacy Policy Generator</a></p>

    </div>
}
