import React from 'react';
import {Link} from "react-router-dom";
import AppLinks from "../AppLinks";

function Logo({width, height}) {
    width = width || 150;
    const style  = {borderRadius: 30};
    return <div>
        <h1 style={{color: "#929495"}}>Play 29</h1>
        <Link to={'/'}>
            <img src={'/logo.png'} style={style} alt="29 Card Game" width={width} />
        </Link>
        <h3 style={{color: "#bbb"}}>Online 29 Card Game</h3>
        <AppLinks />
    </div>
}

export default Logo;
