import React from 'react';
import Logo from "./common/Logo";
import PrivacyLinks from "./privacy/PrivacyLinks";
import Divider from "./common/Divider";
import AppFeaturesList from "./AppFeaturesList";
import LearnToPlayLink from "./RulesLink";
import AppLinks from "./AppLinks";
import PromoYoutube from "./promo/PromoYouTube";

function Homepage() {

    return <div className="container">
        <Logo />
        {/*<AppLinks/>*/}
        <h1>Got Cards ?</h1>
        <h3>🤖 No Robots. Ever.</h3>
        <h3>Only Real Players.</h3>
        <h3 style={{color: "#bbb"}}>29 Card Game</h3>
        <AppFeaturesList/>
        <Divider />
        <PromoYoutube />
        <LearnToPlayLink/>
        <PrivacyLinks/>
        <Divider />
        <AppLinks/>
    </div>
}

export default Homepage;
